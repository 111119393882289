import { Component, FunctionComponent } from "react";
import { Auth } from "aws-amplify";
import { RouteComponentProps } from "react-router-dom";
import { Color, Path, Storage } from "../../../env";
import { Alert, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Button, variant } from "../../form/Button";

export interface NewMemberState {
    fields: Map<Field, string>;
    error: string;
}

export interface NewMemberProps extends RouteComponentProps {
    auth: typeof Auth;
}

export enum Field {
    Username = "username",
    Code = "code",
    Password = "password",
}

export default class NewMember extends Component<NewMemberProps, NewMemberState> {
    constructor(props: NewMemberProps) {
        super(props);
        this.state = {
            fields: new Map([
                [Field.Username, ""],
            ]),
            error: "",
        };
    }

    handleNavigate = (path: Path) => {
        this.props.history.replace(path);
    };

    handleConfirmPassword = (): void => {
        if (this.isValidField(Field.Code, this.state.fields) == false) {
            const error = "Please provide the verification code sent to you by email";
            return this.setState({
                error: error,
            });
        }

        if (this.isValidField(Field.Username, this.state.fields) == false) {
            const error = "Please enter your username sent to you by email";
            return this.setState({
                error: error,
            });
        }

        if (this.isValidField(Field.Password, this.state.fields) == false) {
            const error = "Please enter your new password";
            return this.setState({
                error: error,
            });
        }

        const code: string = this.state.fields.get(Field.Code)!.trim();
        const username: string = this.state.fields.get(Field.Username)!.trim();
        const password: string = this.state.fields.get(Field.Password)!.trim();

        this.props.auth.signIn(username, code).then(user => {
            this.props.auth.completeNewPassword(user, password).then(response => {
                localStorage.setItem(Storage.IsLoggedIn, "true");
                this.props.history.push(Path.CourierDashboard);
            }).catch(err => {
                this.setState({
                    error: err.message
                })
            })
        }).catch(err => {
            this.setState({
                error: err.message
            })
        })
    };

    isValidField = (field: Field, fields: Map<string, string>): boolean => {
        return (
            typeof fields.get(field) !== "undefined" &&
            fields.get(field)!.trim() != ""
        );
    };

    handleChange = (field: Field, value: string): void => {
        const fields = this.state.fields;
        fields.set(field, value);
        this.setState({
            fields: fields,
        });
    };

    handleSubmit = (): void => {
        return this.handleConfirmPassword()
    }

    render() {
        const loginFormProps: NewMemberFormProps = {
            error: this.state.error,
            username: this.state.fields.get(Field.Username)!,
            code: this.state.fields.get(Field.Code)!,
            password: this.state.fields.get(Field.Password)!,
            onChange: this.handleChange,
            onSubmit: this.handleSubmit,
            onNavigate: this.handleNavigate,
        };
        return <NewMemberForm {...loginFormProps} />;
    }
}

export interface NewMemberFormProps {
    error: string;
    username: string;
    code: string;
    password: string;
    onChange: (name: Field, value: string) => void;
    onSubmit: () => void;
    onNavigate: (path: Path) => void;
}

export const NewMemberForm: FunctionComponent<NewMemberFormProps> = (props) => (
    <Container style={{ padding: "1rem" }} fluid>
        <Row>
            <Col style={{ margin: "auto" }} xs={12} md={5} xl={4}>
                <div style={{ textAlign: "center" }}>
                    <img src={"/ecomni.png"} width={150} height={150} />
                    <h1 className={"App-logo"} style={{ fontWeight: 900, verticalAlign: "middle", margin: 0, fontSize: "3rem" }}>Stumbled</h1>
                </div>
                <Alert variant="danger" show={props.error !== ""}>{props.error}</Alert>
                <Card.Text style={{ fontSize: ".9rem", marginTop: "1.5rem", marginBottom: "1.5rem" }}>.</Card.Text>
                <Form.Group style={{ marginTop: "1rem" }} controlId="username">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        id={Field.Username}
                        type="text"
                        name={Field.Username}
                        onChange={(e) =>
                            props.onChange(Field.Username, e.target.value)
                        }
                    />
                </Form.Group>
                <Form.Group style={{ marginTop: "1rem" }} controlId="verification-code">
                    <Form.Label>Verification Code</Form.Label>
                    <Form.Control
                        id={Field.Code}
                        type="text"
                        name={Field.Code}
                        onChange={(e) =>
                            props.onChange(Field.Code, e.target.value)
                        }
                    />
                </Form.Group>
                <Form.Group style={{ marginTop: "1rem" }} controlId="new-password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        id={Field.Password}
                        type="password"
                        name={Field.Password}
                        onChange={(e) =>
                            props.onChange(Field.Password, e.target.value)
                        }
                    />
                </Form.Group>
                <Row style={{ marginTop: "1rem" }}>
                    <Col xs={12}>
                        <Button
                            variant={variant.Primary}
                            name={"Reset Password"}
                            style={{ width: "100%", marginTop: "1rem" }}
                            onClick={props.onSubmit}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
);
