export enum Storage {
    CourierName = "CourierName",
    CourierID = "CourierID",
    IsLoggedIn = "IsLoggedIn",
}

export enum Path {
    Home = "/",
    TermsAndConditions = "/terms-and-conditions",
    Privacy = "/privacy",
    Contact = "/contact",
    CourierDashboard = "/dashboard",
    RegisterMember = "/register/member",
    RegisterMemberConfirmation = "/register/member/confirmation",
    RegisterCourierAddress = "/register/courier/address",
    RegisterCourierName = "/register/courier/name",
    RegisterCourier = "/register/courier",
    RegisterCourierContact = "/register/courier/contact",
    RegisterCourierAccount = "/register/courier/account",
    Login = "/login",
    Customers = "/customers",
    Delivery = "/delivery",
    Deliveries = "/deliveries",
    Payments = "/payments",
    ResetPassword = "/reset-password",
    NewMember = "/new-member",
    Drivers = "/drivers",
}

export enum Color {
    Primary = "#5b5bff",
    Black = "#000000",
    Brown = "#552200",
    DarkGrey = "#3d3d3d",
    Grey = "#C0C0C0",
    LightGrey = "#F8F8FA",
    White = "#FFFFFF",
    Red = "#E82064",
}

export const Sizes = {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    xxl: '1536px',
};
