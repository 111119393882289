import { FunctionComponent } from "react"
import { Button as BButton } from "react-bootstrap"
import { FaArrowLeft, FaArrowRight, FaCopy, FaFacebookF, FaGoogle, FaLink, FaPlus, FaSearch, FaShare, FaTrash } from "react-icons/fa"
import { BiShareAlt } from "react-icons/bi"
import { Color } from "../../env"
import { MdClose } from "react-icons/md"

export enum variant {
    Primary,
    Secondary,
    Danger,
    Success,
}

export interface ButtonProps {
    id?: string
    name?: string
    variant: variant
    icon?: icon
    style?: any
    disabled?: boolean
    buttonShape?: shape
    onClick: () => void
}

export enum shape {
    Circle
}

export enum icon {
    Trash,
    Plus,
    RightArrow,
    LeftArrow,
    Copy,
    Search,
    Link,
    Share,
    Close,
    Facebook,
    Google,
}

export const Button: FunctionComponent<ButtonProps> = (props) => {

    const getColor = () => {
        switch (props.variant) {
            case variant.Secondary:
                return Color.Grey
            case variant.Danger:
                return Color.Red
            case variant.Success:
                return "#198753"
            default:
                return Color.Primary
        }
    }

    let borderRadius = 0
    if (props.buttonShape === shape.Circle) {
        borderRadius = 100
    }

    return (
        <BButton
            id={props.id}
            style={{ ...props.style, margin: "0.1rem", background: getColor(), border: 0, borderRadius: borderRadius }}
            onClick={() => props.onClick()}
        >
            {props.name}{(props.icon) && <Icon icon={props.icon!} />}
        </BButton>
    )
}

export interface IconProps {
    icon: icon
    size?: number
    color?: Color
}

export const Icon: FunctionComponent<IconProps> = (props) => {
    switch (props.icon) {
        case icon.Trash:
            return <FaTrash color={props.color} />
        case icon.Plus:
            return <FaPlus color={props.color} />
        case icon.LeftArrow:
            return <FaArrowLeft color={props.color} />
        case icon.RightArrow:
            return <FaArrowRight color={props.color} />
        case icon.Copy:
            return <FaCopy color={props.color} />
        case icon.Search:
            return <FaSearch color={props.color} />
        case icon.Link:
            return <FaLink color={props.color} />
        case icon.Share:
            return <BiShareAlt color={props.color} />
        case icon.Close:
            return <MdClose color={props.color} />
        case icon.Facebook:
            return <FaFacebookF color={props.color} size={props.size} />
        case icon.Google:
            return <FaGoogle color={props.color} size={props.size} />
        default:
            return <div></div>
    }
}
